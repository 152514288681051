import { useState, useEffect } from "react";
// MUI
import { Box, styled } from "@mui/material";

// Loading element
const LoadingContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: ['Khula', 'sans-serif'],
    marginTop: "5px",
    fontWeight: "600",
});
const LoadingLetter = styled(Box)({
    '@keyframes bounce': {
        '0%, 20%, 50%, 80%, 100%': {
            transform: 'translateY(0)',
        },
        '40%': {
            transform: 'translateY(-5px)',
        },
        '60%': {
            transform: 'translateY(-10px)',
        },
    },
    display: "inline-block",
    animation: "bounce 2s infinite",
    margin: "0 2px",
    fontWeight: "600",
});

// Circle element
const radius = 60; // Radius of the circle
const MeteorTrajectory = styled(Box)({
    width: radius * 2,
    height: radius * 2,
    borderRadius: '50%',
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #D85F28",
});
const Meteor = styled(Box)(({ angle, size }) => ({
    width: size,
    height: size,
    backgroundColor: '#D85F28',
    borderRadius: '50%',
    position: 'absolute',
    top: `${radius + radius * Math.sin((angle * Math.PI) / 180)}px`,
    left: `${radius + radius * Math.cos((angle * Math.PI) / 180)}px`,
    transform: 'translate(-50%, -50%)',
}));

// Logo element
const LogoContainer = styled(Box)({
    width: "50%",
    aspectRatio: "269 / 173",
});

// Circle Container
function CircleContainer({ children }) {
    const [angle, setAngle] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setAngle((prev) => (prev + 1) % 360);
        }, 20);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: "0",
                left: "0",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                backdropFilter: "blur(5px)",
                zIndex: "1000",
            }}
        >
            <MeteorTrajectory>
                {children}
                <Meteor angle={angle} size="10px" />
            </MeteorTrajectory>
        </Box>
    );
}

// Loading Component
function LoadingAnimation() {
    const letters = "Loading".split("");
    return (
        <LoadingContainer>
            {letters.map((letter, index) => (
                <LoadingLetter
                    key={index}
                    style={{ animationDelay: `${index * 0.1}s` }}
                >
                    {letter}
                </LoadingLetter>
            ))}
        </LoadingContainer>
    );
}

// Output
export default function Loader() {
    return (
        <CircleContainer>
            <LogoContainer>
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 269 173"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M60 1H1L103 171L128 118L60 1Z"
                        fill="#D66121"
                        stroke="#DB5F24"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M138 1H71L168 171L203 113L138 1ZM102 14H121L172 103L166 121L102 14Z"
                        fill="#DA5F26"
                    />
                    <path
                        d="M71 1V0.5H70.139L70.5657 1.24779L71 1ZM138 1L138.432 0.749025L138.288 0.5H138V1ZM168 171L167.566 171.248L167.988 171.988L168.428 171.258L168 171ZM203 113L203.428 113.258L203.581 113.005L203.432 112.749L203 113ZM121 14L121.434 13.7514L121.29 13.5H121V14ZM102 14V13.5H101.118L101.571 14.2567L102 14ZM172 103L172.474 103.158L172.545 102.946L172.434 102.751L172 103ZM166 121L165.571 121.257L166.13 122.191L166.474 121.158L166 121ZM71 1.5H138V0.5H71V1.5ZM168.434 170.752L71.4343 0.752206L70.5657 1.24779L167.566 171.248L168.434 170.752ZM202.572 112.742L167.572 170.742L168.428 171.258L203.428 113.258L202.572 112.742ZM137.568 1.25097L202.568 113.251L203.432 112.749L138.432 0.749025L137.568 1.25097ZM121 13.5H102V14.5H121V13.5ZM172.434 102.751L121.434 13.7514L120.566 14.2486L171.566 103.249L172.434 102.751ZM166.474 121.158L172.474 103.158L171.526 102.842L165.526 120.842L166.474 121.158ZM101.571 14.2567L165.571 121.257L166.429 120.743L102.429 13.7433L101.571 14.2567Z"
                        fill="#D85F2A"
                    />
                    <path
                        d="M268 1H151L179 51H218L211 59H186L211 106L253 29H196L191 21H259L268 1Z"
                        fill="#D66121"
                        stroke="#D66121"
                    />
                </svg>
            </LogoContainer>
            <LoadingAnimation />
        </CircleContainer>
    );
}
