import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import {
    NavigationBar,
    Footer,
} from "../Elements/element";

export default function Layout() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <NavigationBar />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
}
