import { Suspense, lazy } from "react";
// component
import Loader from "../Loading/Loader";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
)

// Pages
export const Landing = Loadable(lazy(() => import('../../pages/Landing')));
export const AboutUs = Loadable(lazy(() => import('../../pages/AboutUs')));
export const ServiceDetail = Loadable(lazy(() => import('../../pages/Service Detail/ServiceDetail')));
export const ProjectPage = Loadable(lazy(() => import('../../pages/Project/ProjectPage')));
export const ProjectDetail = Loadable(lazy(() => import('../../pages/Project Detail/ProjectDetail')));
export const Contact = Loadable(lazy(() => import('../../pages/Contact/Contact')));
export const TermOfUse = Loadable(lazy(() => import('../../pages/Term of Use/TermOfUse')));
export const PrivacyPolicy = Loadable(lazy(() => import('../../pages/Privacy Policy/PrivacyPolicy')));
export const SubmittedMessage = Loadable(lazy(() => import('../../pages/Contact/SubmittedMessage')));
export const Page404 = Loadable(lazy(() => import('../../pages/Page404')));