const paths = {
    landing: '/',
    about: '/about',
    serviceDetail: (id = ':id') => `/service/${id}`,
    projects: '/projects',
    projectDetail: (id = ':id') => `/projectDetail/${id}`,
    contact: '/contact',
    termOfUse: '/term',
    privacyPolicy: '/privacy',
    submittedMessage: '/submittedMessage',
    page404: '*',
};

export default paths;