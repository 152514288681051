// react-router-dom
import { Helmet } from "react-helmet";
import { BrowserRouter } from 'react-router-dom';
// Scroll to top
import ScrollToTop from "./components/Nav/ScrollToTop";
// Router
import Router from "./components/Nav/Router";

export default function App() {
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Router />
      </BrowserRouter>
    </>
  );
}

