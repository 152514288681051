import { useRoutes } from "react-router-dom";
// paths
import paths from "./paths";
// layout
import Layout from "./Layout";
// pages
import {
    Landing,
    AboutUs,
    ServiceDetail,
    ProjectPage,
    ProjectDetail,
    Contact,
    TermOfUse,
    PrivacyPolicy,
    SubmittedMessage,
    Page404,
    ConnectionLost,
} from "./pages";

export default function Router() {
    return useRoutes([
        {
            element: <Layout />,
            children: [
                { path: paths.landing, element: <Landing /> },
                { path: paths.about, element: <AboutUs /> },
                { path: paths.serviceDetail(), element: <ServiceDetail /> },
                { path: paths.projects, element: <ProjectPage /> },
                { path: paths.projectDetail(), element: <ProjectDetail /> },
                { path: paths.contact, element: <Contact /> },
                { path: paths.termOfUse, element: <TermOfUse /> },
                { path: paths.privacyPolicy, element: <PrivacyPolicy /> },
                { path: paths.submittedMessage, element: <SubmittedMessage /> },
            ]
        },
        { path: paths.page404, element: <Page404 /> },
    ])
}