import { Suspense, lazy } from "react";
// component
import Loader from "../Loading/Loader";

const Loadable = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
)

// layout
export const NavigationBar = Loadable(lazy(() => import('../Nav/Navbar/NavigationBar')));
export const Footer = Loadable(lazy(() => import('../Nav/Footer/Footer')));

// common
export const MetaTags = Loadable(lazy(() => import('../MetaTags')));
export const LandingSection = Loadable(lazy(() => import('../Sections/LandingSection/LandingSection')));
export const About = Loadable(lazy(() => import('../Sections/About/About')));
export const FAQs = Loadable(lazy(() => import('../Sections/FAQs/FAQs')));
export const Process = Loadable(lazy(() => import('../Sections/Process')));
export const StartYourProject = Loadable(lazy(() => import('../Sections/StartYourProject')));
export const BackToTopBtn = Loadable(lazy(() => import('../Buttons/BackToTopBtn')));

// project
export const Projects = Loadable(lazy(() => import('../Sections/Projects')));
export const ProjectTimeline = Loadable(lazy(() => import('../Sections/ProjectTimeline/ProjectTimeline')));
export const ProjectDisplay = Loadable(lazy(() => import('../../pages/Project/ProjectDisplay')));
export const Introduction = Loadable(lazy(() => import('../../pages/Project Detail/Introduction')));
export const Resolution = Loadable(lazy(() => import('../../pages/Project Detail/Resolution')));
export const Overview = Loadable(lazy(() => import('../../pages/Project Detail/Overview')));

// service
export const Services = Loadable(lazy(() => import('../Sections/Services/Services')));
export const ServiceIntro = Loadable(lazy(() => import('../../pages/Service Detail/ServiceIntro')));
export const Scope = Loadable(lazy(() => import('../../pages/Service Detail/Scope')));
export const Feature = Loadable(lazy(() => import('../../pages/Service Detail/Feature')));
export const SkillAndTool = Loadable(lazy(() => import('../../pages/Service Detail/SkillAndTool')));

// contact
export const ContactForm = Loadable(lazy(() => import('../../pages/Contact/ContactForm')));
export const ClientReview = Loadable(lazy(() => import('../../pages/Contact/ClientReview')));